import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Skeleton from "react-loading-skeleton";

const Certification = () => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     setLoading(true);
  //     setTimeout(() => {
  //         setLoading(false);
  //     }, 500);
  // }, []);

  useEffect(() => {
    document.title = "Ultimate Organic Life | Privacy Policy";
    window.scrollTo(0, 0);
  }, []);
  let loadCheck = 0;
  const [privacyPolicy, setPrivacyPolicy] = useState();
  useEffect(() => {
    if (loadCheck === 0) {
      loadCheck = 1;
      axios.get("privacy-policy")
        .then(resp => {
          // console.log(resp.data.description);
          setPrivacyPolicy(resp.data.description);
        });
    }
  }, []);

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Certification
            <span></span>
          </div>
        </div>
        <br />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className=" border rounded overflow-hidden" style={{ height: '600px',backgroundColor:'#edf3f4' }}>
                <img className="img-fluid h-100 w-100 object-cover" src="/assets/images/certificate.jpg" alt="Certificate" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="border rounded overflow-hidden" style={{ height: '600px',backgroundColor:'#edf3f4' }}>
                <img className="img-fluid h-100 w-100 object-cover" src="/assets/images/certificate-2.jpg" alt="Certificate" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="border rounded overflow-hidden" style={{ height: '600px',backgroundColor:'#edf3f4' }}>
                <img className="img-fluid h-100 w-100 object-cover" src="/assets/images/certificate-3.jpg" alt="Certificate" />
              </div>
            </div>
          </div>
        </div>


      </Layout>
    </div>
  );
};

export default Certification;
