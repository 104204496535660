import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Home from "./pages/Home";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderData } from "./store/layoutData/HeaderData";
import { setFooterData } from "./store/layoutData/FooterData";
import { setFooterBanner } from "./store/layoutData/FooterBanner";
import { setCategoryData } from "./store/layoutData/CategoryData";
import { setSocialMediaData } from "./store/layoutData/SocialMediaData";
import { setFooterAdvertisementData } from "./store/layoutData/FooterAdvertisementData";
import { setHomeBannerData } from "./store/layoutData/HomeBannerData";
import Cart from "./pages/Cart";
import Wishlist from "./pages/Wishlist";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AllProducts from "./pages/AllProducts";
import Blogs from "./pages/Blogs";
import BlogView from "./pages/BlogView";
import Contact from "./pages/Contact";
import AboutUs from './pages/AboutUs';
import CookiePolicy from './pages/CookiePolicy';
import Faq from './pages/Faq';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReturnExchange from './pages/ReturnExchange';
import ShippingDelivery from './pages/ShippingDelivery';
import TermsCondition from './pages/TermsCondition';
import { setShippingChargesData } from "./store/checkoutData/ShippingChargesData";
import Checkout from "./pages/Checkout";
import CategoryWiseProducts from "./pages/CategoryWiseProducts";
import ProductDetails from "./pages/ProductDetails";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import PaymentFailed from "./pages/Payment/PaymentFailed";
import { setUserData } from "./store/UserData";
import TrackOrder from "./pages/TrackOrder";
import MakePayment from "./pages/Payment/MakePayment";
import ForgetPassword from "./pages/ForgetPassword";
import OtpNewPassword from "./pages/OtpNewPassword";
import OtpVerify from "./pages/OtpVerify";
// import { setDhakaShippingZoneData } from "./store/checkoutData/DhakaShippingZoneData";
// import { setOutsideShippingZoneData } from "./store/checkoutData/OutsideShippingZoneData";
import CustomerDashboard from "./pages/CustomerDashboard";
import { setCustomerAddressData } from "./store/checkoutData/CustomerAddressData";
import { setLoadCheckData } from "./store/LoadCheckData";
import { setTopNewsData } from "./store/layoutData/TopNewsData";
import { setBestSellerProductData } from "./store/layoutData/BestSellerProductData";
import { setPopularProductData } from "./store/layoutData/PopularProductData";
import NotFoundPage from "./pages/NotFoundPage";

import GeneratePDF from "./pages/GeneratePDF";
import MyDocument from "./components/pdf/pdfTemplate";
import { setFlashNewsData } from "./store/layoutData/FlashNewsData";
import SearchResult from "./pages/SearchResult";
import Certification from "./pages/Certification";
// import ReactPDF from '@react-pdf/renderer';
// import { Offline, Online } from "react-detect-offline";

function App() {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);

  axios.defaults.baseURL="https://ultimateasiteapi.com/api/";
  // axios.defaults.baseURL="https://ktggz7inf4.sharedwithexpose.com/api/";
  // axios.defaults.baseURL = "http://192.168.100.69:8000/api/";
  axios.defaults.headers.common["Accept"] = 'application/json';
  axios.defaults.headers.common["retry-after"] = 3600;
  var token = null;
  const { UserData } = useSelector((state) => state.UserData);
  if (localStorage.getItem("user") || UserData) {
    var obj = JSON.parse(localStorage.getItem("user"));
    if (UserData) {
      obj = UserData;
    }
    // console.log(obj);
    token = obj.token;
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    // axios.defaults.headers.common["Authorization"] = token;
    // axios.defaults.headers.common["bearerToken"] = token;
  }
  const { LoadCheckData } = useSelector((state) => state.LoadCheckData);
  const navigate = useNavigate();
  if(LoadCheckData == 0){
    axios.get("settings?platform=web")
    .then(resp => {
      // console.log(resp.data);
      dispatch(setCategoryData(resp.data.categories));
      dispatch(setHeaderData(resp.data.headerManagement));
      dispatch(setFooterData(resp.data.footerManagement));
      dispatch(setFooterBanner(resp.data.footerBanner[0]));
      dispatch(setSocialMediaData(resp.data.socialMedia));
      dispatch(setFooterAdvertisementData(resp.data.advertisement));
      dispatch(setHomeBannerData(resp.data.hompageBanner));
      dispatch(setShippingChargesData(resp.data.shipping_charges));
      dispatch(setTopNewsData(resp.data.news));
      dispatch(setPopularProductData(resp.data.popular.data));
      dispatch(setBestSellerProductData(resp.data.bestseller.data));
      dispatch(setFlashNewsData(resp.data.newsPopUpNotification));
    });
      if(localStorage.getItem("user")){
        dispatch(setUserData(JSON.parse(localStorage.getItem("user"))));
          axios
          .get("get-customer-addresses/" + JSON.parse(localStorage.getItem("user")).customer_id)
          .then(function (resp) {
            // console.log(resp.data.data);
            dispatch(setCustomerAddressData(resp.data.data));
            if(resp.data.code===401 || resp.data.message === "Authorization token invalid, You cannot proceed"){
              navigate("/login?q=You%20Have%20To%20Login%20Again",{ replace: true });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      dispatch(setLoadCheckData(1));
  }
  return (
    <>
    
    {/* <Offline><div className="offline_msg"><h5><svg viewBox="0 0 24 24" alt="" class="x1lliihq x1k90msu x2h7rmj x1qfuztq x1eu2eya" fill="currentColor" height="24" width="24"><path d="M23.7805.2195c.2925.293.2925.768 0 1.061l-22.5 22.5C1.134 23.927.942 24 .75 24c-.192 0-.384-.073-.5305-.2195-.2925-.293-.2925-.768 0-1.061l12.1967947-12.1971509c-2.3773758-.1150468-4.79162015.7286756-6.6038447 2.5401009-.3905.3905-1.0235.3905-1.414 0-.3905-.3905-.3905-1.024 0-1.414 2.65728981-2.65728981 6.3696459-3.62280965 9.8005664-2.90852144l2.430738-2.43186193C11.7816792 4.58111626 6.15198938 5.65271062 2.27735 9.52735c-.3905.3905-1.0235.3905-1.414 0-.3905-.3905-.3905-1.0235 0-1.414 4.6714534-4.67191574 11.571522-5.78608437 17.3096457-3.34843552L22.7195.2195c.293-.2925.768-.2925 1.061 0zM12 18.5c.6905 0 1.25.5595 1.25 1.25S12.6905 21 12 21s-1.25-.5595-1.25-1.25.5595-1.25 1.25-1.25zm1.4175-4.81495c.9705.2455 1.8905.741 2.6485 1.499.3905.3905.3905 1.0235 0 1.414-.1955.1955-.451.293-.707.293-.256 0-.512-.0975-.707-.293-.7835333-.7835333-1.8301422-1.1445778-2.8581093-1.0880116L11.574 15.52855l1.8435-1.8435zm3.8154-3.8154c.848.4725 1.649 1.059 2.3685 1.779.391.39.391 1.023 0 1.414-.195.195-.451.293-.707.293-.2555 0-.5115-.098-.707-.293-.7285-.728-1.5575-1.291-2.439-1.7085zm2.1908-2.1908l1.4425-1.4425c.8.545 1.5615 1.168 2.2705 1.877.3905.3905.3905 1.0235 0 1.414-.1955.1955-.451.293-.707.293-.256 0-.5115-.0975-.707-.293-.5933333-.59333333-1.2283333-1.11861111-1.895162-1.57959491L19.4237 7.67885l1.4425-1.4425z"></path></svg>&nbsp;You are currently offline. <a href="#" onClick={()=>window.location.reload()}>Refresh</a></h5></div></Offline> */}
   
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/cart" element={<Cart />} />
      <Route exact path="/wish-list" element={<Wishlist />} />
      <Route exact path="/products" element={<AllProducts />} />
      <Route exact path="/flash-sell" element={<AllProducts />} />

      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/blogs/:slug" element={<BlogView />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route exact path="/about-us" element={<AboutUs />} />
      <Route exact path="/terms-conditions" element={<TermsCondition />} />
      <Route exact path="/cookie-policy" element={<CookiePolicy />} />
      <Route exact path="/returns-exchanges" element={<ReturnExchange />} />
      <Route exact path="/shipping-delivery" element={<ShippingDelivery />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/certification" element={<Certification />} />

      <Route exact path="/checkout" element={<Checkout />} />
      <Route exact path="/checkout/:pId/:qty" element={<Checkout />} />
      <Route exact path="/checkout/:pId/:qty/:attId" element={<Checkout />} />
      <Route exact path="/product-categories/:id/:cname" element={<CategoryWiseProducts />} />

      {/* <Route exact path="/product_details/:id" element={<ProductDetails />} /> */}
      {/* <Route exact path="/product_details/:id/:name" element={<ProductDetails />} /> */}
      {/* <Route exact path="/products/:productslug" element={<ProductDetails />} /> */}
      <Route
  exact
  path="/products/:productslug"
  element={<ProductDetails key={window.location.pathname} />}
/>

      <Route exact path="/search" element={<SearchResult />} />
      {/* Payment Routes */}
      <Route exact path="/payment_success" element={<PaymentSuccess />} />
      <Route exact path="/payment_failed" element={<PaymentFailed />} />
      <Route exact path="/make-payment/:orderID" element={<MakePayment />} />

      <Route exact path="/track-order" element={<TrackOrder />} />

      {/* Login Register Routes */}
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/forgot-password" element={<ForgetPassword />} />
      <Route exact path="/otp-verification/:id" element={<OtpVerify />} />
      <Route exact path="/verify-otp-set-new-password/:customer_id" element={<OtpNewPassword />} />

      {/* Customer Dashboard */}
      <Route exact path="/customer/:pgType" element={<CustomerDashboard />} />
      <Route exact path="/customer/:pgType/:add_id" element={<CustomerDashboard />} />
      <Route path="*" element={<NotFoundPage />} />
      {/* <Route exact path="/generate-invoice" element={ReactPDF.render(<MyDocument />, `/example.pdf`)} /> */}
      <Route exact path="/generate-invoice" element={<GeneratePDF />} />
    </Routes>
    {/* // </Router> */}
    </>
  );
}

export default App;
